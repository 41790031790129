import './bn-sdk'

export function requestPayment(payload) {
    if (window.__NEZHA_BRIDGE__) {
        console.log('IN MINI APP');
        bn.miniProgram.postMessage({ action: 'requestPayment', payload: payload });
        return true
    } else {
        console.log('NOT IN MINI APP');
        return false
    }
}

// Receive messages from mini-program.
bn.onMessage = function (data) {
    console.log('DATA RECIEVED FROM MINI PROGRAM');
    console.log(data); // { action: 'requestPayment', payload: { status: '0'} }
};