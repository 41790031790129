import { Button } from "@mui/material";

export default function AddToCartButton({hasStock, handleClick} : {hasStock: boolean, handleClick: () => void}) {
  return (
    <div className='text-center mt-2'>
      <Button variant="contained" className={`py-2 px-10 w-50 m-auto bg-amber-400 rounded ${hasStock ? "bg-amber-400" : "bg-slate-200"}`} disabled={!hasStock} onClick={handleClick}>
        {hasStock ? 'Add to Cart' : 'No stock'}
      </Button>
    </div>
  )
}