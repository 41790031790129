import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react"
import {toast} from 'react-toastify'
import { IOrder, IQueryOrders, getOrders } from "../api/ProductStore";
import OrdersTable from "./OrdersTable";

export default function Orders({email} : {email: string}) {
    const [recieved, setRecieved] = useState(false)
    const [code, setCode] = useState('')
    const [orders, setOrders] = useState(Array<IOrder>)
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        if (code.length != 6) {
            toast.error('Code length must be 6', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored"})
            return;
        }
        setLoading(true)
        getOrders(email, code)
            .then(res => {
                if (res && res.res && res.res.ok) {
                    setRecieved(true)
                    const body : IQueryOrders = res.body 
                    setOrders(body.orders)
                } else if (res && res.body && res.body.code && res.body.msg) {
                    toast.error(res.body.msg)
                } else {
                    toast.error('Error checking orders, please try again later or contact an administrator')
                }
                setLoading(false)
            })
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                !recieved
                ?
                <div className="h-screen flex gap-2 flex-col items-center justify-center"> 
                    <div>Check your e-mail and enter the code below</div>
                    <TextField disabled className="block w-full disabled" size="small" value={email} id="outlined-basic" label="E-mail" />
                    <TextField className="block w-full" size="small" value={code} id="outlined-basic" label="Code" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setCode(event.target.value);}}/>
                    <div className="mt-2">
                        <Button className="bg-amber-400" variant="contained" onClick={handleSubmit}>Submit</Button>
                    </div>
                </div>
                :
                <div className="flex flex-col items-center ">
                    <h2 className="text-lg" >Last 10 Orders</h2>
                    {orders.map(order => (
                        <div key={order['id']} className="mb-2 w-full">
                            <OrdersTable order={order} />
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}