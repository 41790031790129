import { useState } from 'react';
import { ISimpleProduct } from '../api/ProductStore';
import { useCart } from '../context/Context'
import AddedItemDialog from './AddedItemDialog';
import ProductPrice from './ProductPrice';
import AddToCartButton from './AddToCartButton';
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';

export default function SimpleProduct({product} : {product: ISimpleProduct}) {
  const { increaseCartQuantity, canAddRechargeProduct } = useCart()
  const [open, setOpen] = useState(false)
  const [username, setUsername] = useState('');

  const handleClose = () => {
    setOpen(false);
  }

  const handleClick = () => {
    if (!product.sku || !product.has_stock) {
      toast.error("Product cannot be added to cart")
      return;
    }
    if (product.category == 'recharge') {
      if (!username) {
        toast.error("Username required");
        return;
      }
      if (!canAddRechargeProduct) {
        toast.error("Different username for same product recharge not supported");
        return;
      }
    }
    
    increaseCartQuantity(product.sku, product.name, product.type, product.image_url, Number(product.price), username, product.category)
    setOpen(true)
  }

  return (
    <div>
      <ProductPrice price={product.price} />
      {
        product.category === 'recharge' &&
        <TextField className="block w-full" size="small" value={username} id="outlined-basic" label="Username" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setUsername(event.target.value);}} />
      }
      <AddToCartButton handleClick={handleClick} hasStock={product.has_stock} />
      <AddedItemDialog isOpen={open} handleClose={handleClose} />
    </div>
  )
}