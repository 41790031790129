import { CartItem } from "../context/Context"

export interface IProductList {
    products: Array<IProduct>
    total: number
    max_num_pages: number
}
export interface IQueryOrders {
    orders: Array<IOrder>
}

export interface IOrder {
    id: string
    products: Array<IOrderItem>
    status: string
    total: string
    created_at: string
}
export interface IOrderItem {
    id: string
    name: string
    quantity: number
    total: string
}
export interface IProduct {
    id: string
    sku: string
    name: string
    type: string
    description: string
    image_url: string
    category: string
    recharge_text: string
}

export interface ISimpleProduct extends IProduct {
    price: number
    has_stock: boolean
}
export interface IVariableProduct extends IProduct {
    variations: Array<IProductVariation>
}

export interface IProductVariation {
    sku: string
    name: string
    price: number
    has_stock: boolean
}

export enum QueryProductCategory {
    All = 'all',
    Pin = 'pin',
    Recharge = 'recharge'
}

export const BASE_URL = import.meta.env.VITE_BACKEND_URL + '/wp-json/woo_binance_pay'

export async function fetchProducts(type : string) {
    try {
        const response = await fetch(BASE_URL + '/products?type='+type, 
        {
            headers: {'authorization' : import.meta.env.VITE_APP_ID}
        })
        const body = await response.json();
        return {
            body: body,
            res: response
        }
    } catch {
        return false
    }
}

export async function initGetOrders(email : string) {
    try {
        const response = await fetch(BASE_URL + '/orders-init', {
            headers: {'Content-Type': 'application/json', 'authorization' : import.meta.env.VITE_APP_ID},
            body: JSON.stringify({
                'email' : email
            }),
            method: 'POST'
        })
        const body = await response.json();
        return {
            body: body,
            res: response
        }
    } catch {
        return false
    }
}

export async function getOrders(email : string, code: string) {
    try {
        const response = await fetch(BASE_URL + '/orders', {
            headers: {'Content-Type': 'application/json', 'authorization' : import.meta.env.VITE_APP_ID},
            body: JSON.stringify({
                'email' : email,
                'code' : code
            }),
            method: 'POST'
        })
        const body = await response.json();
        return {
            body: body,
            res: response
        }
    } catch {
        return false
    }
}

export async function fetchProduct(productId : string) {
    try {
        const response = await fetch(BASE_URL + '/product?id='+productId, {
            headers: {'authorization' : import.meta.env.VITE_APP_ID}
        })
        const body = await response.json();
        return {
            body: body,
            res: response
        }
    } catch {
        return false
    }
}

export async function createOrder(cartItems : Array<CartItem>, email : string, isMiniProgram: boolean) {
    try {
        const cartItemsParsed = cartItems.map(({sku, quantity, username}) => ({sku, quantity, username}))
        const source = isMiniProgram ? 'MINI_PROGRAM' : 'WEB'
        const data = {
            items: cartItemsParsed,
            email: email,
            source: source
        }
        const response = await fetch(BASE_URL + '/new-order', {
            method: 'POST', 
            body: JSON.stringify(data), 
            headers: {'Content-Type': 'application/json', 'authorization' : import.meta.env.VITE_APP_ID}
        })
        const body = await response.json();
        return {
            body: body,
            res: response
        }
    } catch {
        return false
    }
}