import { useParams } from "react-router-dom"
import Product from "../components/Product";

export function ProductPage() {
    const {id} = useParams();
    if (id) {
        return (
            <div className="px-4">
                <Product productID={id} />
            </div>
        )
    }
    return <div></div>
}