import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react"
import {toast} from 'react-toastify'
import { initGetOrders } from "../api/ProductStore";
import Orders from "./Orders";

export default function QueryOrders() {
    const [email, setEmail] = useState('')
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleContinue = () => {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email.length > 100 || !expression.test(email)) {
            toast.error('Invalid e-mail', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored"})
        } else {
            setLoading(true)
            initGetOrders(email)
                .then(res => {
                    if (res && res.res && res.res.ok) {
                       setSent(true);
                    } else if (res && res.body && res.body.code && res.body.msg) {
                        toast.error(res.body.msg)
                    } else {
                        toast.error('Error checking orders, please try again later or contact an administrator')
                    }
                    setLoading(false)
                })
        }
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                sent
                ?
                <div>
                    <Orders email={email} />
                </div>
                :
                <div className="h-screen flex flex-col items-center justify-center"> 
                    <div className="mb-3">Please enter your e-mail address to obtain your past orders.</div>
                    <TextField className="block w-full" size="small" value={email} id="outlined-basic" label="E-mail" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setEmail(event.target.value);}} />
                    <div className="mt-2">
                        <Button className="bg-amber-400" variant="contained" onClick={handleContinue}>Continue</Button>
                    </div>
                </div>
            }
        </div>
    )
}