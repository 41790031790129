import { Card, CardContent } from "@mui/material";
import { IOrder } from "../api/ProductStore";

export default function OrdersTable({order} : {order: IOrder}) {
    return (
        <Card className="w-full" >
            <CardContent>
                <div>ID #{order['id']}</div>
                <div>Status: {order['status']}</div>
                <div>Date: {order['created_at']} UTC</div>
                <hr className="my-1" />
                <table className="w-full text-left" >
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                    {order['products'].map(product => (
                        <tr key={product['id']} >
                            <td>{product['name']}</td>
                            <td>{product['quantity']}</td>
                            <td>${product['total']}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <hr className="my-1" />
                <div className="w-full" >
                    <div className="font-bold">Total ${order['total']}</div>
                </div>
            </CardContent>
        </Card>
                    
    )
}