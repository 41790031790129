import { ReactNode, createContext, useContext, useState } from "react";

type CartProviderProps = {
    children: ReactNode
}

export type CartItem = {
    id: number,
    sku: string,
    name: string,
    quantity: number,
    type: string,
    image_url: string,
    price: number,
    username: string,
    category: string
}

type CartContext = {
    increaseCartQuantity: (sku: string, name: string, type: string, image_url: string, price: number, username: string, category: string) => void
    decreaseCartQuantity: (id: number) => void
    getItemQuantity: (id: number) => number
    getCartItems: () => CartItem[]
    getSubtotal: () => number
    emptyCart: () => void
    canAddRechargeProduct: (sku: string, username: string) => boolean
}

const CartContext = createContext({} as CartContext);

export function useCart() {
    return useContext(CartContext)
}

export function CartProvider({children}: CartProviderProps) {
    const [cartItems, setCartItems] = useState<CartItem[]>([])

    function increaseCartQuantity(sku: string, name: string, type: string, image_url: string, price: number, username: string, category: string) {
      const newItem : CartItem = {id: cartItems.length, sku, quantity:1,name,type,price,image_url,username,category}
      setCartItems(currItems => {
        // if (currItems.find(item => ((item.sku === sku && category==='pin') || (item.sku===sku && item.username===username && category==='recharge'))) == null) {
        if (currItems.find(item => (item.sku === sku)) == null) {
          return [...currItems, newItem];
        } else {
          return currItems.map(item => {
            // if ((item.sku===sku && category==='pin') || (item.sku===sku && item.username===username && category==='recharge')) {
            if (item.sku === sku && item.quantity < 10) {
              return { ...item, quantity: item.quantity + 1 }
            } else {
              return item
            }
          })
        }
      })
    }

    function canAddRechargeProduct(sku: string, username: string) {
      const item = cartItems.find(item => item.category === 'recharge' && item.sku === sku);
      if (!item || item.username === username) {
        return true;
      }
      return false;
    }

    function getItemQuantity(id: number) {
        return cartItems.find(item => item.id === id)?.quantity || 0
    }

    function getCartItems() {
      return cartItems
    }

    function decreaseCartQuantity(id: number) {
      setCartItems(currItems => {
        if (currItems.find(item => (item.id === id))?.quantity === 1) {
          return currItems.filter(item => item.id !== id)
        } else {
          return currItems.map(item => {
            if (item.id === id) {
              return { ...item, quantity: item.quantity - 1 }
            } else {
              return item
            }
          })
        }
      })
    }

    function getSubtotal() {
      return cartItems.reduce((acc, cartItem) => {
        return acc + (cartItem.price * cartItem.quantity);
      }, 0)
    }

    function emptyCart() {
      setCartItems([]);
    }

    return (
        <CartContext.Provider value={{increaseCartQuantity, getItemQuantity, getCartItems, decreaseCartQuantity, getSubtotal, emptyCart, canAddRechargeProduct}} > 
          {children}
        </CartContext.Provider>
    )
}