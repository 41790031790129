export default function ProductPrice({price} : {price: number}) {

  const numberFormat = (num : number) => {
    if (!num || isNaN(num)) {
        return num;
    }
    return num.toFixed(2)
  }

  return (
    <div>
      <div className='font-bold text-right'>
        Price ${numberFormat(Number(price))}
      </div>
    </div>
  )
}