import './App.css'
import { CartProvider } from './context/Context';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { ProductPage } from './pages/ProductPage';
import { CartPage } from './pages/CartPage';
import { BottomNavigation, BottomNavigationAction, Container } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HistoryIcon from '@mui/icons-material/History';
import { ToastContainer } from 'react-toastify';
import { OtherPage } from './pages/OtherPage';
import ReorderIcon from '@mui/icons-material/Reorder';
import { TermsPage } from './pages/TermsPage';
import { ReturnPolicyPage } from './pages/ReturnPolicyPage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { QueryOrdersPage } from './pages/QueryOrdersPage';

function App() {
  const navigate = useNavigate();
  return (
    <CartProvider>
      <Container maxWidth="sm" disableGutters className='bg-slate-100 my-container'>
        <div>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/product/:id' element={<ProductPage />} />
            <Route path='/cart' element={<CartPage />} />
            <Route path='/other' element={<OtherPage />} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
            <Route path='/return-policy' element={<ReturnPolicyPage />} />
            <Route path='/terms-and-conditions' element={<TermsPage />} />
            <Route path='/query-orders' element={<QueryOrdersPage />} />
          </Routes>
        </div>
      </Container>
      <BottomNavigation
          className='items-center fixed bottom-0 w-full border-t'
          showLabels
          value={window?.location.pathname}
          onChange={(_, newValue) => {
            navigate(newValue)
          }}
        >
          <BottomNavigationAction value='/' label="Shop" icon={<StorefrontIcon />} />
          <BottomNavigationAction value='/query-orders' label="Orders" icon={<HistoryIcon />} />
          <BottomNavigationAction value='/cart' label="Cart" icon={<ShoppingCartIcon />} />
          <BottomNavigationAction value='/other' label="Other" icon={<ReorderIcon />} />
      </BottomNavigation>
      <ToastContainer 
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
    </CartProvider>
  );
}

export default App;
