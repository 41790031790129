import { useEffect, useState } from 'react'
import { IProductVariation, IVariableProduct } from '../api/ProductStore'
import { useCart } from '../context/Context'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import AddedItemDialog from './AddedItemDialog'
import ProductPrice from './ProductPrice'
import AddToCartButton from './AddToCartButton'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


export default function VariableProduct({product} : {product: IVariableProduct}) {
    const { increaseCartQuantity, canAddRechargeProduct } = useCart()
    const [selectedVariation, setSelectedVariation] = useState<IProductVariation|undefined>()
    const [selectVariation, setSelectVariation] = useState('');
    const [open, setOpen] = useState(false)
    const [username, setUsername] = useState('');

    useEffect(() => {
        const variations = product.variations
        if (variations && variations.length > 0) {
            setSelectedVariation(variations[0])
            setSelectVariation(variations[0].sku)
        }
    }, [product])
    
    const handleClick = () => {
        if (!selectedVariation) {
          toast.error("A variation should be selected");
          return;
        }
        if (product.category == 'recharge') {
          if (!username) {
            toast.error("Username required");
            return;
          }
          if (!canAddRechargeProduct(selectedVariation.sku, username)) {
            toast.error("Different username for same product recharge not supported");
            return;
          }
        }
        increaseCartQuantity(selectedVariation.sku, selectedVariation.name, 'variable', product.image_url, Number(selectedVariation.price), username, product.category)
        setOpen(true);
    }

    const handleChange = (event: SelectChangeEvent) => {
      setSelectVariation(event.target.value);
      const variation = product.variations.find(p => p.sku === event.target.value)
      if (variation) {
        setSelectedVariation(variation);
      }
    }

    const handleClose = () => {
      setOpen(false);
    }

    return (
      <div className='mt-2'>
        <FormControl size='small' fullWidth>
          <InputLabel id="demo-simple-select-label">Variation</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectVariation}
            label="Variation"
            onChange={handleChange}
            className='text-center'
          >
            {product.variations?.map((variation) =><MenuItem key={variation.sku} value={variation.sku}>{variation.name}</MenuItem>)}
          </Select>
        </FormControl>

        {selectedVariation &&
          <ProductPrice price={selectedVariation.price} />
        }
        {
          product.category === 'recharge' &&
          <TextField className="block w-full" size="small" value={username} id="outlined-basic" label="Username" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setUsername(event.target.value);}} />
        }
        <AddToCartButton handleClick={handleClick} hasStock={selectedVariation?.has_stock || false} />
        <AddedItemDialog isOpen={open} handleClose={handleClose} />
      </div>
    )
}