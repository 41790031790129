import { useEffect, useState } from "react"
import { IProduct, fetchProducts, QueryProductCategory } from "../api/ProductStore"
import { Link } from "react-router-dom"
import { CircularProgress } from "@mui/material";

export default function ProductList() {
    const [products, setProducts] = useState<Array<IProduct>>([])
    const [loading , setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
          const res = (await fetchProducts(QueryProductCategory.All))
          if (res && res.res.ok) {
            let products : Array<IProduct> = res.body.products;
            products = products.sort((a, b) => a.name.localeCompare(b.name));
            // const edited_products = products.map(p => {
            //   return {
            //     ...p,
            //     image_url: p.image_url.replace('localhost', '192.168.0.102')
            //   }
            // })
            setProducts(products);
          }
          setLoading(false);
      }
      fetchData();
    }, [])

    return (
      loading ?
        <div className="flex items-center justify-center h-screen text-center" >
          <CircularProgress />
        </div>
      :
      <div className="text-center" >
        <div className="text-center flex flex-row mb-3 place-content-center">
          <div className="basis-2/3">
            <img className="" src="/banner-image.png" />
          </div>
        </div>
        <div className='grid grid-cols-2 gap-2 px-4'>
          {products.map(product => (
            <Link to={'/product/'+product.id} key={product.id}>
              <div className="flex flex-col" >
                <img className="w-full aspect-video object-cover rounded-md" alt={product.image_url} src={product.image_url || '/placeholder.png'} />
                <div className='text'>{product.name}</div>
              </div>
            </Link>
            ))}
        </div>
      </div>
      )
}