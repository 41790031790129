import { Link } from "react-router-dom";

export function OtherPage() {
    return (
        <div className="px-4">
            <div>
                <div className="center text-center">
                    <Link className="no-underline block p-3 center bg-slate-200 my-2" to='/terms-and-conditions'>Terms and Conditions</Link>
                    <Link className="no-underline block p-3 center bg-slate-200 my-2" to='/privacy-policy'>Privacy Policy</Link>
                    <Link className="no-underline block p-3 center bg-slate-200 my-2" to='/return-policy'>Return Policy</Link>
                </div>
                <div className="mt-5 font-light" >Version {import.meta.env.VITE_VERSION}</div>
            </div>
        </div>
    )
}