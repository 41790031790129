import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from "@sentry/integrations";

const activate_sentry = import.meta.env.VITE_ACTIVATE_SENTRY == 'true'
if (activate_sentry) {
  Sentry.init({
    dsn: "https://cd69bfc848f96841e8184b555ca8d89a@o4506021115985920.ingest.sentry.io/4506021119197184",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [import.meta.env.VITE_BACKEND_URL],
      }),
      new Sentry.Replay(),
      new CaptureConsole()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
