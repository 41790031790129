import { useEffect, useState } from "react"
import { IProduct, ISimpleProduct, IVariableProduct, fetchProduct } from "../api/ProductStore"
import VariableProduct from "./VariableProduct"
import SimpleProduct from "./SimpleProduct"
import { CircularProgress } from "@mui/material"

export default function Product({productID} : {productID: string}) {
  const [product, setProduct] = useState<IProduct>()
  const [loading , setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
        const res = await fetchProduct(productID)
        if (res && res.res.ok) {
          const product = res.body
          // const edited_product = 
          // {
          //   ...product,
          //   image_url: product.image_url.replace('localhost', '192.168.0.102')
          // }
          setProduct(product);
        }
        setLoading(false)
    }
    fetchData();
}, [productID])

  let description = ''
  if (product) {
      description = product.description
  }
  const htmlDesc = { __html:description }; 

    return (
      loading ?
        <div className="flex items-center justify-center h-screen text-center" >
          <CircularProgress />
        </div>
      :
      <div>
        {product &&
        <div>
          <div className="text-center border-b border-solid border-slate-400 w-full pb-2" >
            <img alt={product.name} className="w-full" src={product.image_url || '/placeholder.png'} />
          </div>
          <div className="text-center font-bold my-1">
            <div>{product.name}</div>
          </div>
          {product.type === 'variable' ?
            <VariableProduct product={product as IVariableProduct} ></VariableProduct> :
            <SimpleProduct product={product as ISimpleProduct} ></SimpleProduct>}
            <div className="mt-2" >
              <div dangerouslySetInnerHTML={htmlDesc} />
            </div>
        </div>
        }
      </div>
    )
}