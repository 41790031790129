import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";

export default function AddedItemDialog({isOpen, handleClose} : {isOpen: boolean, handleClose: () => void}) {
  return (
    <Dialog open={isOpen} onClose={handleClose} >
      <DialogTitle className='text-center' >Product added to cart</DialogTitle>
      <DialogActions>
        <Button size="small" variant='contained' className='rounded bg-amber-400 p-1 text-black' onClick={handleClose}>
          Continue Shopping
        </Button>
        <Button size="small" variant='contained' className='rounded bg-amber-400 p-1 text-black'>
          <Link to={'/cart'} >Go to checkout</Link>
        </Button>
      </DialogActions>
    </Dialog>
  )
}