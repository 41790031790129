import { useEffect, useState } from "react"
import { CartItem, useCart } from "../context/Context"
import {createOrder } from '../api/ProductStore'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, CircularProgress, FormControlLabel, FormGroup, TextField } from "@mui/material";
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { requestPayment } from "../lib/bn";
import Checkbox from '@mui/material/Checkbox'; 

export default function Cart() {
    const { getCartItems, increaseCartQuantity, decreaseCartQuantity, getSubtotal, emptyCart } = useCart()
    const [cartItems, setCartItems] = useState<Array<CartItem>>([])
    const [subtotal, setSubtotal] = useState(0)
    const [email, setEmail] = useState('')
    const [loading , setLoading] = useState(false);
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setCartItems(getCartItems())
        setSubtotal(getSubtotal())
    }, [getCartItems, getSubtotal])

    const handleIncrease = (item: CartItem) => {
        if (item.quantity < 10) {
            increaseCartQuantity(item.sku, item.name, item.type, item.image_url, item.price, item.username, item.category)
        }
    }

    const handleDecrease = (item: CartItem) => {
        decreaseCartQuantity(item.id)
    }

    async function handlePay () {
        if (!checked) {
            toast.error('You must agree with Terms & Conditions to proceed', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored"})
            return;
        }
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (email.length > 100 || !expression.test(email)) {
            toast.error('Invalid e-mail', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "colored"})
        } else {
            setLoading(true)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const isMiniProgram = window.__NEZHA_BRIDGE__;
            createOrder(cartItems, email, isMiniProgram)
            .then(res => {
                if (res && res.res.ok) {
                    if (isMiniProgram) {
                        const body = res.body;
                        const pbody = {
                            nonceStr: body.nonceStr,
                            package: `prepay_id=${body.package}`,
                            paySign: body.paySign,
                            timeStamp: body.timeStamp,
                            certSn: body.certSn,
                            merchantId: body.merchantId
                        }
                        console.log(pbody);
                        requestPayment(pbody)
                    } else if (res.body.redirect_url) {
                        window.location.href = res.body.redirect_url
                    }
                    emptyCart()
                    setLoading(false)
                } else if (res && res.body.code && res.body.msg) {
                    toast.error(res.body.msg)
                    setLoading(false)
                }
            })
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
      };

    return (
        cartItems.length === 0 ?
        <div className='h-screen flex items-center justify-center'>
            <div className='text-center border p-10 rounded-lg bg-slate-300'>
                <RemoveShoppingCartIcon fontSize="large" />
                <div>The cart is empty</div>
            </div> 
        </div>
        :
        <div className='w-full py-2'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            {cartItems.map(item => {
                return (
                    <div key={item.id} className='pb-2 items-center grid content-center text-center border-b grid-cols-4 justify-between'>
                        <div className=''>
                            <img className='h-16 w-full object-cover rounded' src={item.image_url} />
                        </div>
                            <div>
                                <div className='font-bold'> 
                                    {item.name}
                                </div>
                                {
                                    item.category === 'recharge' ?
                                    <span>(Username: {item.username})</span> :
                                    <span></span>
                                }
                            </div>
                        <div className='flex items-center'>
                            <span onClick={() => handleDecrease(item)} className='cursor-pointer m-auto flex items-center justify-center p-0 text-red-500' >
                                {item.quantity === 1 ?
                                    <DeleteIcon />
                                    :
                                    <RemoveCircleIcon />
                                }
                            </span>
                            <span>{item.quantity}</span>
                            <span onClick={() => handleIncrease(item)} className='cursor-pointer m-auto flex items-center justify-center p-0 text-green-500'>
                                <AddCircleIcon />
                            </span>
                        </div >
                        <div className='font-bold'>${(item.quantity * item.price).toFixed(2)}</div>
                    </div>
                )   
            })}
            <div className='p-3 font-bold flex justify-between font-lg text-lg'>
                <span>Total</span>
                <div>${subtotal.toFixed(2)}</div>
            </div>
            <span>Enter your email address for product delivery</span>
            <div className="mt-2" >
                <TextField className="block w-full" size="small" value={email} id="outlined-basic" label="E-mail" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setEmail(event.target.value);}} />
            </div>
            <FormGroup className="text-sm mt-3" >
                <FormControlLabel 
                    className="text-sm" 
                    control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} 
                    label='I have read and agree with the Terms & Conditions and the Privacy Notice' />
            </FormGroup>
            <div className="mt-2 m-auto w-full text-center">
                <button className="bg-yellow-400 py-2 px-6 font-medium rounded-md" onClick={handlePay}>Pay with Binance</button>
            </div>
            
        </div>
    )
}